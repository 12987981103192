*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  background: radial-gradient(circle at top left, #c52017, #c5201722),
    radial-gradient(circle at bottom right, #044096, #044096);
  /* top left */
  background-position: 0 0;
  display: flex;
  justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#app {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  width: 100%;
  max-width: 40rem;
}

header {
  padding: 2rem;
}

.items {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: min-content;
  overflow: auto;
  padding-inline: 1rem;
}

.item {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2rem;
  padding: 1rem 2rem;
  animation: fade-in 0.5s ease-in-out both;
  animation-delay: calc(var(--i, 0) * 0.1s);
  border: 0.5rem solid transparent;
  border-radius: 1rem;
}

.item.-loading {
  animation: pulse 1s ease-in-out infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

::view-transition {
  animation-duration: 2s;
  transition-duration: 2s;
}

.item[data-index='0'] {
  border-color: #fff3;
}

.user-name {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-location {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  opacity: 0.7;
}

.user-weather {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  font-size: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0.5rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* mobile devices */
@media (max-width: 40rem) {
  .item {
    padding: 1rem;
    grid-column-gap: 1rem;
  }
  .user-name {
    align-self: self-end;
    font-size: 1.5rem;
  }
  .user-weather {
    font-size: 1.5rem;
  }
}

input[type='text'] {
  appearance: none;
  padding: 1rem;
  width: 100%;
  background: #fff3;
  border: 1px solid #fff6;
  border-radius: 1rem;
  font-size: 2rem;
  color: white;
}
/* make the placeholder white-ish */
input[type='text']::placeholder {
  color: #fff9;
}

input[type='text']:focus {
  outline: none;
  /* subtle glow */
  box-shadow: 0 0 0 0.25rem #fff6;
}
